.backgroundImage01 {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  animation: shake 13s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.backgroundImage02 {
  left: 0;
  position: absolute;
  top: 0;
  height: 100%;
  animation: shake02 10s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.backgroundImage03 {
  left: -10px;
  position: absolute;
  top: 0;
  height: 100%;
  animation: shake03 10s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.frontImage {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 98;
}

.interactionLayer {
  z-index: 99;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  pointer-events: none;
  animation: blink 10s;
  animation-iteration-count: infinite;
}
.speakerLayer {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 97;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  23% {
    opacity: 0;
    transform: scale(0.95);
  }
  25% {
    opacity: 0.8;
    transform: scale(1);
  }
  27% {
    opacity: 0.8;
    transform: scale(1);
  }
  30% {
    opacity: 0;
    transform: scale(1.03);
  }
  100% {
    opacity: 0;
  }
}

@keyframes shake {
  0% {
    transform: rotate(-0.1deg) translate(16px);
  }
  50% {
    transform: rotate(0.1deg) translate(-16px);
  }
  100% {
    transform: rotate(-0.1deg) translate(16px);
  }
}

@keyframes shake02 {
  0% {
    transform: rotate(-0.15deg) translate(-12px);
  }
  50% {
    transform: rotate(0.15deg) translate(12px);
  }
  100% {
    transform: rotate(-0.15deg) translate(-12px);
  }
}

@keyframes shake03 {
  0% {
    transform: translate(-10px);
  }
  10% {
    transform: translate(4px);
  }
  20% {
    transform: translate(10px);
  }
  30% {
    transform: translate(4px);
  }
  40% {
    transform: translate(4px);
  }
  50% {
    transform: translate(10px);
  }
  60% {
    transform: translate(-10px);
  }
  70% {
    transform: translate(-4px);
  }
  80% {
    transform: translate(4px);
  }
  90% {
    transform: translate(-4px);
  }
  100% {
    transform: translate(-4px);
  }
}
